 @media (min-width: 50em) {
     .is_animating {
         .animated_wrapper {
             opacity: 1;
             -ms-transform: translateY(0);
             transform: translateY(0);
              -webkit-transition-delay: 0.5s;
             transition-delay: 0.5s;
        }
    }
     .animated_wrapper {
         opacity: 0;
         -ms-transform: translateY(20px);
         transform: translateY(20px);
         transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 0.7s cubic-bezier(.19, 1, .22, 1);
    }
     .panel-banner.load {
    }
     .panel-banner {
    }
     .panel-banner.load {
         h1 {
             opacity: 1;
             -ms-transform: translateY(0);
             transform: translateY(0);
             -webkit-transition-delay: 0.2s;
             transition-delay: 0.2s;
        }
         p {
             opacity: 1;
             -ms-transform: translateY(0);
             transform: translateY(0);
             -webkit-transition-delay: 0.5s;
             transition-delay: 0.5s;
        }
         .menu-holder {
             opacity: 1;
             -ms-transform: translateY(0);
             transform: translateY(0);
             -webkit-transition-delay: 0.7s;
             transition-delay: 0.7s;
        }
         .p-circle {
             opacity: 1;
             -ms-transform: translateY(0);
             transform: translateY(0);
             -webkit-transition-delay: 0.7s;
             transition-delay: 0.7s;
        }
    }
     .panel-banner {
         h1 {
             opacity: 0;
             -ms-transform: translateY(20px);
             transform: translateY(20px);
             transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 0.7s cubic-bezier(.19, 1, .22, 1);
        }
         p {
             opacity: 0;
             -webkit-transform: translateY(20px);
             transform: translateY(20px);
             transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 0.7s cubic-bezier(.19, 1, .22, 1);
        }
         .menu-holder {
             opacity: 0;
             -webkit-transform: translateY(20px);
             transform: translateY(20px);
             transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 0.7s cubic-bezier(.19, 1, .22, 1);
        }
         .p-circle {
             opacity: 0;
             -webkit-transform: translateY(20px);
             transform: translateY(20px);
             transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 0.7s cubic-bezier(.19, 1, .22, 1);
        }
    }
   
   
  ///Home animation
   .is_animating { 
   .home-hero {
     
      h2 {
             opacity: 1;
             -ms-transform: translateY(0);
             transform: translateY(0);
             -webkit-transition-delay: 0.2s;
             transition-delay: 0.2s;
        }
     
      .quick-links {
            opacity: 1;
             -ms-transform: translateY(0);
             transform: translateY(0);
             -webkit-transition-delay: 0.5s;
             transition-delay: 0.5s;
     }
     
     
     }
     
   }
   
   
   .home-hero {
     
      h2 {
            opacity: 0;
             -ms-transform: translateY(20px);
             transform: translateY(20px);
             transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 0.7s cubic-bezier(.19, 1, .22, 1);
        }
     
     .quick-links {
        opacity: 0;
             -webkit-transform: translateY(20px);
             transform: translateY(20px);
             transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 0.7s cubic-bezier(.19, 1, .22, 1);
     }
     
     
     }
   
     //body animation 
   .aside-section .entry-content {
         opacity: 0;
         -webkit-transform: translateY(20px);
         transform: translateY(20px);
         transition: opacity 1s cubic-bezier(.19,1,.22,1),-webkit-transform .7s cubic-bezier(.19,1,.22,1);
         transition: opacity 1s cubic-bezier(.19,1,.22,1),transform .7s cubic-bezier(.19,1,.22,1);
         transition: opacity 1s cubic-bezier(.19,1,.22,1),transform .7s cubic-bezier(.19,1,.22,1),-webkit-transform .7s cubic-bezier(.19,1,.22,1);
    }
     .animated.aside-section .entry-content {
         opacity: 1;
         -webkit-transform: translateY(0);
         transform: translateY(0);
         transition-delay: .5s;
    }
     .text-line-left h3, .text-line-left p {
         position: relative;
         display: inline-block;
    }
     .text-line-left>* {
         opacity: 0;
         -webkit-transition: opacity 100ms ease-in 551ms,transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,-webkit-transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,color 600ms;
         -moz-transition: opacity 100ms ease-in 551ms,transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,-webkit-transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,color 600ms;
         -ms-transition: opacity 100ms ease-in 551ms,transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,-webkit-transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,color 600ms;
         -o-transition: opacity 100ms ease-in 551ms,transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,-webkit-transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,color 600ms;
         transition: opacity 100ms ease-in 551ms,transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,-webkit-transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,color 600ms;
         -webkit-transform: translateX(-5px);
         -moz-transform: translateX(-5px);
         -ms-transform: translateX(-5px);
         -o-transform: translateX(-5px);
         transform: translateX(-5px);
         display: inline-block;
    }
     .text-line-left.animated>*{
         -webkit-transform: translateX(0);
         -moz-transform: translateX(0);
         -ms-transform: translateX(0);
         -o-transform: translateX(0);
         transform: translateX(0);
         -webkit-transition: opacity 100ms ease-in 551ms,transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,-webkit-transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,color 600ms;
         -moz-transition: opacity 100ms ease-in 551ms,transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,-webkit-transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,color 600ms;
         -ms-transition: opacity 100ms ease-in 551ms,transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,-webkit-transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,color 600ms;
         -o-transition: opacity 100ms ease-in 551ms,transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,-webkit-transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,color 600ms;
         transition: opacity 100ms ease-in 551ms,transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,-webkit-transform 400ms cubic-bezier(0.215,.61,.355,1) 700ms,color 600ms;
    }
     .text-line-left.animated * {
         opacity: 1;
    }
     .text-line-left.animated h3:before {
         width: 100%;
         opacity: 0;
         visibility: hidden;
         -webkit-transition: width 450ms cubic-bezier(0.55,.055,.675,.19) 0ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.55,.055,.675,.19) 0ms;
         -moz-transition: width 450ms cubic-bezier(0.55,.055,.675,.19) 0ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.55,.055,.675,.19) 0ms;
         -ms-transition: width 450ms cubic-bezier(0.55,.055,.675,.19) 0ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.55,.055,.675,.19) 0ms;
         -o-transition: width 450ms cubic-bezier(0.55,.055,.675,.19) 0ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.55,.055,.675,.19) 0ms;
         transition: width 450ms cubic-bezier(0.55,.055,.675,.19) 0ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.55,.055,.675,.19) 0ms;
    }
     .text-line-left.animated h3:after {
         width: 0;
         opacity: 1;
         visibility: visible;
         padding: 0;
         -webkit-transition: width 450ms cubic-bezier(0.215,.61,.355,1) 551ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.215,.61,.355,1) 0ms;
         -moz-transition: width 450ms cubic-bezier(0.215,.61,.355,1) 551ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.215,.61,.355,1) 0ms;
         -ms-transition: width 450ms cubic-bezier(0.215,.61,.355,1) 551ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.215,.61,.355,1) 0ms;
         -o-transition: width 450ms cubic-bezier(0.215,.61,.355,1) 551ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.215,.61,.355,1) 0ms;
         transition: width 450ms cubic-bezier(0.215,.61,.355,1) 551ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.215,.61,.355,1) 0ms;
    }
     .text-line-left h3:after {
         position: absolute;
         content: '';
         height: 100%;
         top: 0;
         right: 0;
         width: 100%;
         background-color: #1e2023;
         z-index: 2;
         -webkit-transition: width 450ms cubic-bezier(0.215,.61,.355,1) 0ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.215,.61,.355,1) 0ms;
         -moz-transition: width 450ms cubic-bezier(0.215,.61,.355,1) 0ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.215,.61,.355,1) 0ms;
         -ms-transition: width 450ms cubic-bezier(0.215,.61,.355,1) 0ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.215,.61,.355,1) 0ms;
         -o-transition: width 450ms cubic-bezier(0.215,.61,.355,1) 0ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.215,.61,.355,1) 0ms;
         transition: width 450ms cubic-bezier(0.215,.61,.355,1) 0ms,opacity 0ms ease-in 551ms,visibility 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.215,.61,.355,1) 0ms;
         opacity: 0;
         box-sizing: content-box;
         visibility: hidden;
    }
     .text-line-left h3:before {
         position: absolute;
         content: '';
         height: 100%;
         top: 0;
         left: 0;
         width: 0;
         padding: 0;
         background-color: #1e2023;
         z-index: 1;
         -webkit-transition: width 450ms cubic-bezier(0.55,.055,.675,.19) 551ms,opacity 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.55,.055,.675,.19) 0ms;
         -moz-transition: width 450ms cubic-bezier(0.55,.055,.675,.19) 551ms,opacity 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.55,.055,.675,.19) 0ms;
         -ms-transition: width 450ms cubic-bezier(0.55,.055,.675,.19) 551ms,opacity 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.55,.055,.675,.19) 0ms;
         -o-transition: width 450ms cubic-bezier(0.55,.055,.675,.19) 551ms,opacity 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.55,.055,.675,.19) 0ms;
         transition: width 450ms cubic-bezier(0.55,.055,.675,.19) 551ms,opacity 0ms ease-in 551ms,background 600ms,padding 450ms cubic-bezier(0.55,.055,.675,.19) 0ms;
         opacity: 1;
         box-sizing: content-box;
         visibility: visible;
    }
}
 .is_animating {
     .side-entry {
         .menu-title {
             opacity: 1;
             -ms-transform: translateY(0);
             transform: translateY(0);
             -webkit-transition-delay: 0.2s;
             transition-delay: 0.2s;
        }
         .menu-holder {
             opacity: 1;
             -ms-transform: translateY(0);
             transform: translateY(0);
             -webkit-transition-delay: 0.5s;
             transition-delay: 0.5s;
        }
    }
}
 .side-entry {
     .menu-title {
         opacity: 0;
         -ms-transform: translateY(20px);
         transform: translateY(20px);
         transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 0.7s cubic-bezier(.19, 1, .22, 1);
    }
     .menu-holder {
         opacity: 0;
         -webkit-transform: translateY(20px);
         transform: translateY(20px);
         transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 0.7s cubic-bezier(.19, 1, .22, 1);
    }
}

.hero-header svg {
    z-index: 1;
}

.is_animating {
.page-header::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.34);
   
  }
  .home-slider .slide:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.34);
  }
   
}

.page-header::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
   -webkit-transition:  background-color 0.4s ease;
    transition: background-color 0.4s ease;
    background-color: #fff;
    z-index: 0;
  }


.home-slider .slide:after {
    -webkit-transition:  background-color 0.4s ease;
    transition: background-color 0.4s ease;
     background-color: #fff;
}


/*
.page-header::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include blue_grad();
        z-index: 100;
    }
  
  

  .page-header::after {
        content: '';
        -webkit-transition: left 1s cubic-bezier(0.77, 0, 0.175, 1);
        transition: left 1s cubic-bezier(0.77, 0, 0.175, 1);
    }
   .is_animating {
     .page-header {
        left: 100%;
        -webkit-transition: left 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: left 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
}
*/
