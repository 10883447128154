// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.wpcf7-form {
label {
   font-size: 14px;
    line-height: 1;
}
}

.wpcf7-form-control {
    display: block;
    width: 100%;
    padding: .5rem .75rem .5rem 0;
    font-size: 1rem;
    line-height: 1.25;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.15);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


textarea.wpcf7-form-control {
  max-height: 120px;
}

.wpcf7-form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: $red;
    outline: none;
}


#ee-single-page-checkout-dv input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=file]) {
    max-width: 100%;
    min-width: 50%;
    width: 100%;
    background: #f5f5f5;
    border: none;
    padding: 10px 0 10px 10px;
}

#ee-single-page-checkout-dv .ee-asterisk, #ee-single-page-checkout-dv .ee-input-error, #ee-single-page-checkout-dv .ee-required-text {
    /* font-weight: bold; */
    color: #ff0038 !important;
    font-size: 12px;
    margin: 10px 0;
}

.spco-attendee_information-dv {
  .ee-reg-form-attendee-dv {
      padding: 0;
      margin: 30px 0 0;
      border: 1px solid #eee;
      border-radius: 0;
  }
}

.spco-step-dv  {
  
  .ee-reg-qstn {
   margin-bottom: 10px;
  }

  .spco-ticket-info-dv {
     display: none; 
    }
}