/* Preloader **/

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 99999;
}
#status {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    /* background-image:url(../img/status.gif);  */
    
    background-repeat: no-repeat;
    background-position: center;
    margin: -100px 0 0 -100px;
}
.lazylinepainter.is_complete {
    svg {
       
		path:nth-child(2) {
            fill-opacity: 1;
        }
        path:nth-child(3) {
            fill-opacity: 1;
        }
        path:last-child {
            fill-opacity: 1;
        }
    }
}
.lazylinepainter {
    svg {
        path {
            fill: #003594;
            transition: fill-opacity .4s ease;
            -webkit-transition: fill-opacity .4s ease;
        }
        path:nth-child(3) {
            fill: $green;
        }
    }
}
/** --Preloader-- **/

.home-slider {
    position: relative;
    .more-offers {
        text-align: center;
        position: absolute;
        bottom: 90px;
        left: 50%;
        margin-left: -40px;
        width: 40px;
        p {
            color: $red;
            margin: 0 0 10px;
        }
    }
    .arrows {
        width: 40px;
        height: 60px;
        /* position: relative; */
        
        bottom: -60px;
    }
    .arrows path {
        stroke: $red;
        fill: transparent;
        stroke-width: 1px;
        animation: arrow 2s infinite;
        -webkit-animation: arrow 2s infinite;
    }
    @keyframes arrow {
        0% {
            opacity: 0
        }
        40% {
            opacity: 1
        }
        80% {
            opacity: 0
        }
        100% {
            opacity: 0
        }
    }
    @-webkit-keyframes arrow
    /*Safari and Chrome*/
    
    {
        0% {
            opacity: 0
        }
        40% {
            opacity: 1
        }
        80% {
            opacity: 0
        }
        100% {
            opacity: 0
        }
    }
    .arrows path.a1 {
        animation-delay: -1s;
        -webkit-animation-delay: -1s;
        /* Safari 和 Chrome */
    }
    .arrows path.a2 {
        animation-delay: -0.5s;
        -webkit-animation-delay: -0.5s;
        /* Safari 和 Chrome */
    }
    .arrows path.a3 {
        animation-delay: 0s;
        -webkit-animation-delay: 0s;
        /* Safari 和 Chrome */
    }
}

.home-hero {
    min-height: 480px;
    height: calc(100vh - 100px);
    width: 100%;
    max-height: 480px;
    position: relative;
}
.home-slider .slide {
    min-height: 480px;
    height: calc(100vh - 100px);
    width: 100%;
    max-height: 480px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: table;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
       // background: rgba(209, 237, 251, 0.11);
        z-index: 1;
    }
    .desc-txt {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        position: relative;
        z-index: 10;
        .entry {
      max-width: 390px;
			padding: 0 20px;
			//background: rgba(255,255,255,.8);
			padding: 40px;
      text-align: right;
          a {
            display: block;
            margin: 10px 0 0;
            i {
              font-size: 25px;
              line-height: 1;
              position: relative;
              top: 5px;
              margin-left: 4px;
            }
          }
          
        }
        h2 {
      font-size: 22px;
			line-height: 1.2;
			color: #fff;
			margin: 0 0 10px;
          
          
          
        }
      
     // h2:before {content:"\201C"; left:0; top:30px;}
    // h2:after {content:"\201D"; right:0; bottom:0;}
      
        p {
          color: #fff;
          margin: 0;
        }
    }
  
}

.home-slider {
  svg {
    position: absolute;
    bottom: 0;
    width: 52vw;
    height: 5vw;
}
}

.l_angle {
   left: 0;
}

.r_angle {
  right: 0;
}


.featured_classes {
  .section_title {
    h2, h3{
      color: $red;
    } 
  }
}

/* Arrows */

.slick-dots {
		display: none !important;
	}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    z-index: 10;
    position: absolute;
    top: 44%;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    width: 30px;
    height: 33px;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}
.slick-prev:before,
.slick-next:before {
    font-family: "Ionicons";
    font-size: 40px;
    line-height: 1;
    content: "";
    opacity: 1;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
    left: 20px;
}
.slick-prev:before {
    content: "\f3d5";
}
.slick-next:before {
    content: "\f3d6";
}
.slick-next {
    right: 20px;
}


//call to actions

.sq-cta {
	position: absolute;
    top: 50%;
    margin-top: -35px;
    right: 0;
    max-height: 70px;
	
	.btn:active:focus, .btn:focus, btn:hover {
		color: #fff;
	}
	a:focus, a:hover {
   	color: #fff;
		text-decoration: none;
}
	
	em {
		display: inline-block;
		max-width: 140px;
		white-space: normal;
		text-align: left;
		font-size: 16px;
		line-height: 1.2;
		font-style: normal;
		
	}
	
	.play-btn {
    border: 2px solid #fff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: inline-block;
    line-height: 33px;
    text-align: center;
    margin: 0px 7px 0 0px;
    font-size: 24px;
    position: relative;
    top: -5px;
}
	
}


//title 

.home {
	
	.blue-title-header h1{
		
		
	}
}


.latest-news-section {
    margin: 50px;
    .section-title {
        margin: 0 0 30px;
        h3 {
            color: $green;
         
			margin: 0;
			text-transform: capitalize;
			
        }
    }
    .news-slider {
        .image {
            margin: 0;
        }
		
		.slick-arrow {
			background: rgba(246,129,33,.64);
			padding: 0;
			width: 30px;
			height: 40px;
			&:hover {
				background: $red;
			}
		}
        .slick-next {
            top: inherit;
            right: inherit;
            left: 30px;
			bottom: 0;
        }
        .slick-prev {
            top: inherit;
			left: 0;
			bottom: 0;
			
        }
        .slick-next:before,
        .slick-prev:before {
            content: "\f3d3";
            
        }
        .slick-next:hover:before,
        .slick-prev:hover:before {
            
        }
        .slick-prev:before {
            content: "\f3d2";
        }
        .entry {
            h4 {
                color: $blue;
                font-size: 20px;
                line-height: 1.2;
                margin: 0 0 20px;
            }
        }
    }
}
@media (min-width: 1200px) {
    .latest-news-section .news-slider {
        .slick-next {} .slick-prev {}
    }
}
@mixin easeOut {
    transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
}
@mixin break {
    @media screen and (max-width: 760px) {
        @content;
    }
}
.quick-link-section {
    
   	min-height: 480px;
	max-height: 580px;
    height: calc(100vh - 234px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    ul {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        overflow: hidden;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        list-style: none;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        li.column.active {
			  width: 40%;
			.bg_holder {
				//transform: scale(1.15);
				//-moz-transform: scale(1.15);
				//-webkit-transform: scale(1.15);
				//-o-transform: scale(1.15);
				//-ms-transform: scale(1.15);
				
                &:after {
                    background: rgba(0, 83, 159, .7);
                }
            }
			.content {
          		  .desc {
            h3 {
                &:after {
                    width: 100%;
                }
            }
			}
            .hover_desc {
                p {
                    opacity: 1;
                    -ms-transform: translateY(0);
                    transform: translateY(0);
                    transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 1s cubic-bezier(.19, 1, .22, 1);
                    -webkit-transition-delay: 0.2s;
                    transition-delay: 0.2s;
                }
                a {
                    opacity: 1;
                    -ms-transform: translateY(0);
                    transform: translateY(0);
                    transition: opacity 1s cubic-bezier(.19, 1, .22, 1), transform 1s cubic-bezier(.19, 1, .22, 1);
                    -webkit-transition-delay: 0.5s;
                    transition-delay: 0.5s;
                }
				.link {
					.play-btn {
						margin: 0 5px 0 0;
					}
					&:hover {
						color: #fff;
						
					}
				}
            }
			}
        }
        li.column {
            position: relative;
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            width: 20%;
            overflow: hidden;
          
				transition: all 1s ease;
				-moz-transition: all 1s ease;
				-ms-transition: all 1s ease;
				-webkit-transition: all 1s ease;
				-o-transition: all 1s ease;
            /* easeInCubic */
            
            .hover_desc {
                display: block;
                max-width: 310px;
                position: relative;
                color: #fff;
                p {
                    opacity: 0;
                    -ms-transform: translateY(20px);
                    transform: translateY(20px);
                }
                a {
                    opacity: 0;
                    -ms-transform: translateY(20px);
                    transform: translateY(20px);
                }
            }
            .bg_holder {
                width: 105%;
                min-height: 480px;
				max-height: 580px;
				height: calc(100vh - 234px);
                background-size: cover;
                background-repeat: no-repeat;
				transition: all 1s ease;
				-moz-transition: all 1s ease;
				-ms-transition: all 1s ease;
				-webkit-transition: all 1s ease;
				-o-transition: all 1s ease;
				transform: scale(1);
				-moz-transform: scale(1);
				-webkit-transform: scale(1);
				-o-transform: scale(1);
				-ms-transform: scale(1);
                &:after {
                    content: "";
                    background: rgba(0, 0, 0, .40);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
            .content {
                display: block;
                height: 100%;
                text-align: left;
                padding: 15px 20px;
                color: #fff;
                position: absolute;
                z-index: 1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                width: 100%;
                height: 100%;
                .desc {
                   position: relative;
					top: 80px;
					/* -webkit-transform: translateY(-50%); */
					/* transform: translateY(-50%); */
                    h3 {
                        color: #fff;
                       	font-size: 22px;
                        line-height: 1.3;
                        display: inline-block;
                        &:after {
                            content: "";
                            display: block;
                            height: 2px;
                            width: 60px;
                            background-color: #fff;
                            margin-top: 8px;
                            -webkit-transition: all 400ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
                            -moz-transition: all 400ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
                            -o-transition: all 400ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
                            transition: all 400ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
							
							
							
                            /* easeInCubic */
                        }
                    }
                }
            }
        }
    }
}
@keyframes strip1 {
    0% {
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
.we-work-with {
    display: none;
    margin: 50px 0 0;
    .client-slider {
        .slick-slide {
            padding: 60px 50px 90px;
        }
        .slick-current {
            border-bottom: 5px solid $red;
        }
        .slick-next:before,
        .slick-prev:before {
            color: $red;
        }
        .slick-next {
            right: 0;
        }
        .slick-prev {
            left: 0;
        }
    }
}

.contact-section {
    background-image: url(../images/fuels-liquified-natural-gas.jpg);
    min-height: 600px;
    height: calc(100vh - 300px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: table;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 83, 159, .5);
        z-index: 1;
    }
    .desc-txt {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        position: relative;
        z-index: 2;
        .entry {
            max-width: 680px;
            margin: auto;
        }
        h3 {
            text-transform: uppercase;
            font-size: 34px;
            line-height: 1.2;
            color: #fff;
            margin: 0 0 30px;
        }
    }
}
//Reponsive
@media (max-width: 992px) {
    .news-slider .entry {
        margin: 30px 0 0;
    }
	
	.quick-link-section ul li.column .content {
		padding: 15px 10px;
	}
	
	.quick-link-section ul li.column .content .desc h3 {
   
    font-size: 16px;
    
}
	
	.quick-link-section ul li.column .content .desc p {
		font-size: 14px;
		line-height: 1.4;
	}
	
	
    .customer-stories-section .entry {
        margin: 30px 0 0;
    }
    .latest-news-section {
        margin: 50px 20px;
    }
    .latest-news-section .news-slider .slick-prev {
        top: 50%;
        left: 0px;
    }
    .latest-news-section .news-slider .slick-next {
        top: 50%;
        right: 0px;
        left: inherit;
    }
	
.section-title h3 {
   	font: 32px/1 dincompregular,Arial,Helvetica,sans-serif !important;
    
}
	
	.sq-cta {
		    margin-top: -27px;
		a {
			padding: 10px 15px;
		}
		
		.play-btn {
			
			width: 30px;
			height: 30px;
			
			line-height: 31px;
			
			
			font-size: 22px;
			
			top: -4px;
}
		
		 em {
   
    font-size: 14px;
    line-height: 1.2;
    
}
		
		
	}
	
	
	
}
@media (max-width: 768px) {
    .home-slider .slide .desc-txt h2 {
        font-size: 22px  !important;
        line-height: 1.2;
    }
    
   
    .contact-section .desc-txt h3 {
        font-size: 24px;
        line-height: 1.2;
    }
	
	
	
	
	.slick-dots {
		display: block !important;
	}
	

	
	
}




@media (max-width: 668px) {
  
  
  .home-slider .more-offers {
    display: none;
}
  
  .slick-next, .slick-prev {
    display: none !important;
  }
    
.home-hero, .home-slider .slide {
    min-height: 280px;
    height: calc(100vh - 290px);
    width: 100%;
}
  
  
  .home-slider .slide .desc-txt .entry {
  
    padding: 0 20px;
    margin-left: 0;
   
}
  
  .home-slider .slide .desc-txt h2 {
    font-size: 14px !important;
    line-height: 1.2;
}
  
  .home-slider .slide .desc-txt p {
 
    font-size: 12px;
}
  
  .slick-next, .slick-prev {
    display:none;
  }
  
  .full-w-bg .call-cta .card-copy p {
    br {
      display: none;
    }
  }
    
    
}

//iphone5 and below

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 568px)  { 
    


}
