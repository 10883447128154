//home 
.home-hero {
     position: relative;
     height: calc(100vh - 85px);
     width: 100%;
     min-height: 450px;
     // background-image: url(../images/pexel-bg-img.jpg);
     background-color: #929292;
     background-position: 50% 50%;
     background-size: cover;
     background-repeat: no-repeat;
     .entry {
         padding: 0 30px;
         text-align: center;
         max-width: 840px;
         margin: auto;
         h2 {
             color: #fff;
             text-transform: uppercase;
             font-size: 36px;
             line-height: 1;
             font-family: "ToyotaType-Black";
        }
    }
}
 .v-align_wrapper {
     @include v-align_outer();
}
 .v-align_content {
     @include v-align_inner();
     position: relative;
     z-index: 5;
}
 .quick-links {
     position: absolute;
     bottom: 40px;
     width: 100%;
     left: 0;
     right: 0;
     z-index: 5;
     .inner-entry {
         background: #fff;
         width: 100%;
         display: block;
         -webkit-box-shadow: 3px 5px 14px -6px rgba(0, 0, 0, 0.52);
         -moz-box-shadow: 3px 5px 14px -6px rgba(0, 0, 0, 0.52);
         box-shadow: 3px 5px 14px -6px rgba(0, 0, 0, 0.52);
         .article {
             border-left: 1px solid #b5b5b5;
             ;
        }
         .article:first-child {
             border: none;
             .thumbnail {
                 padding-left: 15px;
            }
        }
         .article:first-child {
             .thumbnail {
                 padding: 15px 0 15px 15px;
            }
        }
         .thumbnail {
             padding: 15px 0 15px 0;
             overflow: hidden;
        }
         figure.img-holder {
             margin: 0 10px 0 0;
             max-width: 140px;
             float: left;
        }
         .desc {
             h4 {
                 font-size: 14px;
                 line-height: 1;
                 text-transform: uppercase;
                 margin: 0px;
                 color: $red;
            }
             time {
                 margin: 0px;
                 font-size: 12px;
                 color: #828282;
                 display: block;
            }
             p {
                 margin: 0;
                 font-size: 14px;
                 line-height: 1.3;
                 color: $drk;
            }
        }
    }
     .event-datetimes {
         margin: 10px 0 0;
         ul {
             margin: 0;
             padding: 0;
             li {
                 float: none;
                 width: inherit;
               
            }
        }
    }
     .download-iCal-frm {
         display: none;
    }
}
 .full-w-bg {
     padding: 100px 0;
     background: $red;
     background-image: url(../images/pexel-bg-img.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
     .call-cta {
         @include drop_shadow();
         padding: 30px 20px;
         max-width:550px;
         background: #fff;
         text-align: center;
         margin: auto;
         .card-copy {
             text-align: center;
             h3 {
                 color:$red;
                 font-size: 38px;
                 line-height: 1;
                 margin: 0 0 20px;
            }
           p {
             font-size: 18px;
             line-height: 1.3;
           }
        }
         .card-cta {
        }
    }
}
 .hero-header {
     background-image: url(../images/CPS_SubHero_5.jpg);
     background-size: cover;
     background-repeat: no-repeat;
     background-position: center center;
     height: 260px;
     position: relative;
     svg {
         position: absolute;
         bottom: 0;
         width: 50%;
         height: 4vw;
         z-index: 1;
    }
}
 //page header 
.page-header.short-header {
     height: calc(100vh - 460px);
     min-height: 200px;
     max-height: 280px;
     position: relative;
     // background: #bfbfbf;
     .v-align {
         @include vertical-align();
         top: 42%;
         z-index: 1;
         .entry {
             padding: 0;
        }
    }
  
}
 .page-header {
     position: relative;
     height: calc(100vh - 0px);
     width: 100%;
     min-height: 660px;
     background-size: cover;
     background-repeat: no-repeat;
     background-position: 50% 50%;
     // background-image: url(../images/placeholder-bg.jpg);
     .menu-holder {
    }
     .entry-content {
         padding: 0;
         max-width: 600px;
         h1 {
             color: #fff;
             text-transform: uppercase;
            font-family: "ToyotaType-Bold";
             font-size: 42px;
             line-height: 1;
        }
         p {
             color: #4c4c4c;
             font-size: 18px;
             line-height: 1.3;
             font-weight: 100;
             margin: 0;
        }
    }
     .p-circle {
         width: 60px;
         height: 60px;
         border-radius: 50%;
         background: $purple;
         display: block;
         position: absolute;
         z-index: 5;
         bottom: 30px;
         left: 50%;
         margin-left: -30px;
         text-align: center;
         cursor: pointer;
         i {
             line-height: 60px;
             font-size: 28px;
             color: #fff;
        }
    }
    /* &:after {
         content: "";
         position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         right: 0;
         background: rgba(0, 0, 0, 0.4);
         z-index: 1;
    }
     */
}
 //Our Team 
.persons {
     position: relative;
}
 .persons:before, .persons:after {
     clear: both;
     content: " ";
     display: table;
}
 .persons .person {
     display: inline-block;
     cursor: pointer;
     margin-top: 30px;
}
 .persons .person .avatar {
     width: 100%;
     display: block;
     text-align: center;
     position: relative;
}
 .persons .person .avatar .img-holder {
     width: 100%;
     display: block;
     text-align: center;
     position: relative;
     overflow: hidden;
     margin: 0;
}
 .persons .person .avatar .img-holder:hover {
     .hover_img {
         -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
         filter: alpha(opacity=1);
         -moz-opacity: 1;
         -khtml-opacity: 1;
         opacity: 1;
    }
}
 .persons .person.active .avatar {
     img {
    }
     .hover_img {
         -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
         filter: alpha(opacity=1);
         -moz-opacity: 1;
         -khtml-opacity: 1;
         opacity: 1;
    }
}
 .persons .person .avatar img {
     position: relative;
     display: inline-block;
     width: 100%;
     vertical-align: middle;
}
 .persons .person .avatar .hover_img {
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
     -moz-opacity: 0;
     -khtml-opacity: 0;
     opacity: 0;
     -webkit-transition: all .4s ease-in;
     -moz-transition: all .4s ease-in;
     transition: all .4s ease-in;
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
}
 .persons .person.active .avatar .border-bottom {
     border-bottom: 1px solid $red;
     position: absolute;
     bottom: 0;
     z-index: 10;
     left: 0;
     right: 0;
     .down-arrow {
         position: absolute;
         bottom: -24px;
         left: 50%;
         width: 0;
         height: 0;
         margin-left: -12px;
         border: 12px solid transparent;
         border-top-color: $red;
         pointer-events: none;
         -webkit-transition: left .3s;
         -o-transition: left .3s;
         transition: left .3s;
    }
}
 .persons .person .js-description {
     display: none;
}
 @media screen and (max-width: 768px) {
     .persons .person {
         width: 50%;
    }
}
 @media screen and (max-width: 420px) {
     .persons .person {
         width: 50%;
    }
}
 .persons .js-dropdown {
     position: relative;
     width: 100%;
     padding: 0 15px 0;
}
 .persons .js-dropdown:before, .persons .js-dropdown:after {
     clear: both;
     content: " ";
     display: table;
}
 @media screen and (max-width: 400px) {
     .persons .js-dropdown {
         border-left: medium none;
    }
}
 .persons .js-dropdown .js-dropdown-content {
     background: #ececec;
     position: relative;
     width: 100%;
     padding: 40px 30px;
     .desc-txt {
         margin: 25px 0 0;
    }
}
/* .persons .js-dropdown .js-dropdown-content .js-dropdown-arrow {
     position: absolute;
     bottom: 100%;
     left: 50%;
     width: 0;
     height: 0;
     margin-left: -12px;
     border: 24px solid transparent;
     border-top-color: $blu;
     pointer-events: none;
     -webkit-transition: left .3s;
     -o-transition: left .3s;
     transition: left .3s;
}
 */
 .persons .js-dropdown .js-dropdown-content p {
     margin: 0px;
     padding: 0;
}
 .js-dropdown-content a {
     color: #707576;
     @include ease_out();
     &:hover {
         text-decoration: none;
    }
}
 .js-dropdown-content {
     .desc-txt {
         h3 {
             font-size: 42px;
             line-height: 1;
             margin: 0 0 4px;
        }
         ul {
             padding: 0;
             margin: 0;
             li {
                 font-size: 14px;
                 line-height: 1.2;
                 list-style: none;
                 display: block;
                 margin-bottom: 2px;
                 color: #505050;
            }
        }
    }
}


///default page.

.entry_section {
   h2, h3, h4 {
        font-family: "ToyotaType-Book";
         margin: 0 0 20px;
        }
  
  
  ul, ol {
    margin-top: 0;
    margin: 0 0 30px;
    padding-left: 20px;
    li {
      color: #5a5a5a;
      margin: 0 0 3px;
      span {
         color: $drk;
      }
    }
  }
  
  
  
}



 //Contact 
.contact_section {
  margin-bottom: 115px;
     .card {
         border:none;
         padding: 20px 30px;
         border-radius: 0;
         @include drop_shadow();
         @include cubic;
         height: 100%;
       &:hover {
           @include no_drop_shadow();
       }
       
       a {
         color: $red;
         &:hover {
           color: $red;
           text-decoration: underline;
         }
       }
       
    }
}
 //FAQs
//Accordions 
.panel-wrap {
     .panel {
         border-width: 0 0 1px 0;
         border-style: solid;
         border-color: $red;
         background: none;
         box-shadow: none;
    }
     .panel-title {
         margin: 0;
    }
     .panel:last-child {
         border-bottom: none;
    }
     .panel-group > .panel:first-child .panel-heading {
         border-radius: 0;
    }
     .panel-group .panel {
         border-radius: 0;
    }
     .panel-group .panel + .panel {
         margin-top: 0;
    }
     .panel-heading {
         background-color: transparent;
         border-radius: 0;
         border: none;
         color: $red;
         padding: 0;
    }
     .panel-title a {
         font-family: "ToyotaType-Semibold";
         display: block;
         color: $drk;
         padding: 14px 0;
         position: relative;
         font-size: 16px;
         font-weight: 400;
    }
     .panel-body {
         background: #fff;
         padding: 20px 0;
         color: #5a5a5a;
         h3 {
             color: $drk;
             font-size: 18px;
             margin: 0 0 4px;
        }
         .info {
             display: block;
        }
       
       ul {
         margin: 0 0 30px;
        padding-left: 20px;
         li {
         
       }
       }
       
    }
     .panel:last-child .panel-body {
         border-radius: 0;
    }
     .panel:last-child .panel-heading {
         border-radius: 0;
    }
     .panel:last-child .panel-heading.active {
         border-radius: 0;
    }
    /* #bs-collapse icon scale option */
     .panel-heading a:before {
         content: "\f3d0";
         position: absolute;
         font-family: "Ionicons";
         right: 5px;
         top: 10px;
         font-size: 20px;
         transition: all 0.5s;
         color: $drk;
    }
     .panel-heading a[aria-expanded=false]:before {
         content: "\f3d0";
    }
     .panel-heading a[aria-expanded=true]:before {
         content: "\f3d8";
    }
}
 //Events Archive


.event-content {
  strong {
    color: $drk;
  }
}

.events_section {
     .espresso_events {
         margin-bottom: 45px;
         .thumbnail {
             border: 4px solid $red;
             height: 100%;
             transition: all .3s ease-in-out;
             box-shadow: 0 0 2px 0 rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.24);
             .img-holder {
                 position: relative;
                 .start_date {
                     position: absolute;
                     top: -4px;
                     left: -4px;
                     background:$red;
                     color: #fff;
                     padding: 7px 10px 11px;
                     font-size: 12px;
                     text-align: center;
                     width: 57px;
                     @include ease_out();
                     @include drop_shadow();
                     .day {
                    }
                     span {
                         display: block;
                         font-family: ToyotaType-Semibold;
                    }
                     .date {
                         font-size: 18px;
                         line-height: 1;
                    }
                }
            }
             .desc_txt {
                 display: block;
                 padding: 20px;
                 padding-bottom: 4.5rem;
                 h2 {
                     font-size: 18px;
                    
                         &:before {
                             content: "";
                             background-image: url(../images/toyota-logo.png);
                             background-size: 45px;
                             background-repeat: no-repeat;
                             background-position: left;
                             width: 52px;
                             height: 35px;
                             float: left;
                        }
                    
                }
            }
             .event-content {
                 h4 {
                     font-size: 14px;
                     line-height: 1;
                     font-family: ToyotaType-Semibold;
                }
               
               strong {
                 color: $drk;
               }
               
            }
             &:hover {
                 @include lg_drop_shadow();
            }
           
           
           .event-datetimes, h4, .card-bottom {
             color: $drk;
           }
         
        }
         &.espresso_event_categories-lexus {
             .thumbnail {
                 border-color: #5f5f5f;
                 .img-holder .start_date {
                     background: #5f5f5f;
                }
                 .desc_txt h2 {
                    color: #5f5f5f;
                    
                        
                         &:before {
                             background-image: url(../images/lexus-logo.png);
                        }
                    
                }
            }
        }
         .card-bottom {
             position: absolute;
             bottom: 22px;
             left: 30px;
             width: calc(100% - 64px);
             height: 53px;
           
           [itemprop="name"] {
             font-size: 14px;
              line-height: 1.3;
              display: block;
           }
        }
    }
     .bg-img {
    }
     .espresso-event-list-wrapper-dv {
         padding: 0;
         &.alt-side {
             padding: 30px 0px 30px 30px;
        }
    }
     .event-datetimes {
         ul {
             padding: 15px 0;
             margin: 0;
             display: block;
             li {
                 list-style: none;
                 display: block;
                 width: 100%;
                 overflow: hidden;
             
                 margin: 0;
                 .dashicons {
                     top: -3px;
                     position: relative;
                }
            }
        }
         .download-iCal-frm {
             display: none;
        }
    }
     .venue-info {
         .venue-address-h3 {
        }
    }
     .ticket-selector-submit-btn {
         float: none;
         background: none;
         border: 1px solid $red;
         color: $red;
         padding: 12px 45px;
    }
     .event-content {
         p {
             font-size: 14px;
             line-height: 1.3;
        }
    }
     .ee-venue-h3 {
         float: left;
         width: 30px;
         margin-right: 6px;
    }
     [itemprop="address"] {
        
         line-height: 1.3;
    }
}


.events_section .style_li .event-datetimes ul li {
  font-size: 14px;
}

 .single-entry-section {
     .ee-clearfix .ee-event-datetimes-li {
         float: none;
         width: 100%;
       
    }
     .display-tckt-slctr-tkt-details {
         display: none;
    }
     .tckt-slctr-tbl-td-name, .tckt-slctr-tbl-td-price, .ee-ticket-selector-ticket-details-th, .ee-ticket-selector-ticket-price-th {
         display: none;
    }
     .cntr, .jst-cntr {
         text-align: left;
    }
     .ticket-selector-submit-btn, .ticket-selector-submit-btn-wrap {
         float: none;
    }
}
 //event espresso
.tags-links a:before {
     display: none;
}
 .event-sidebar {
     .ee-event-datetimes-li {
         margin-bottom: 30px;
         span {
                margin: 0 0 3px;
                display: inline-block;
               
        }
         .dashicons {
             margin: -3px 10px 0 0;
             display: inline-block;
        }
    }
     .no-tkt-slctr-ticket-content-dv {
         display: none;
    }
     .event-tickets {
         br {
             display: none;
        }
         .no-tkt-slctr-ticket-dv {
             border:none;
             margin: 10px 0 0;
             padding: 0;
             width: 100%;
        }
    }
   .espresso-venue-dv {
     span {
      // font-size: 14px;
     }
     
   }
   
   b, strong {
    font-weight:normal;
    font-family: "ToyotaType-Semibold";
    
}
   
}
 //Courses


.intro_courses {
  .thumbnail {
    box-shadow: 0 0 2px 0 rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.24);
    -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.24);
    height: 100%;
    position: relative;
    .desc-txt {
      padding: 25px;
      padding-bottom: 4.5rem;
      h3 {
        font-size: 20px;
        font-family: ToyotaType-Book;
        margin: 0 0 15px;
      }
      .card-bottom {
        position: absolute;
        bottom: 0;
        left: 25px;
        width: calc(100% - 64px);
        height: 53px;
        a {
          i {
            font-size: 25px;
            line-height: 1;
            position: relative;
            top: 5px;
            margin-left: 4px;
          }
        }
        
      }
    }
  }
}


.course_desc {
   box-shadow: 0 0 2px 0 rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.24);
  padding: 20px;
     .desc_row {
         margin-bottom: 0;
         .content {
             padding: 0;
           h3 {
             margin: 0 0 15px;
             font-size: 32px;
             small {
              font-size: 55%;
              display: block;
              text-transform: uppercase;
              color: #797979;
              margin: 3px 0 0;
                
             }
           }
        }
       
       ul {
         margin: 0;
         padding: 10px 0 30px 20px;
         li {
          color: #5a5a5a;
         }
       }
       
    }
  
  .collapse {
    .course_info {
   border-top:  1px solid transparent;
   padding: 20px 20px;
   margin-left: -20px;
    margin-right: -20px;
    }
    &.show {
      .course_info {
         border-top:  1px solid #e8e8e8;
   
      }
   
    }
}
  
   .list-inline  {
      padding: 0;
      margin: 0;
    }
  
}

.course_info {
   border-top:  1px solid #e8e8e8;
   padding: 20px 20px;
   margin-left: -20px;
    margin-right: -20px;
  .article {
    margin-bottom: 15px;
  }
  .thumbnail {
    text-align: left;
    background: #fff;
    padding: 0;
       
    .desc {
      color: #5a5a5a;
    
      h4 {
        font-size: 18px;
        line-height: 1.2;
        color: $drk;
      }
    }
}
  
  
   
  
}

.drk_container {
    background: #5a5a5a;
    padding: 80px 0 45px;
    color: #fff;
  p {
    color: #eee;
  }
  
.course_desc .desc_row  ul {
    li {
       color: #eee;
    }
  }
  
.desc {
  h4 {
    color: $drk;
  }   
  p {
    color: #5a5a5a;
  }
}
  
}


// checkout
.registration-checkout {
.side-entry.affix {
    position: relative;
     top: 0;
    }
}

.espresso-notices, .espresso-ajax-notices {
    font-weight: normal !important;
}

#espresso-notices-error, #espresso-ajax-notices-error {
    border-color: $red !important;
}

//thank you

 .ee-attention {
    border: none !important;
    color: $drk;
   
}


.thank-you {

  .ee-attention {
    border: none;
    color: $drk;
   
}
  
}

//responsive


@media (max-width: 992px) {
.intro_courses .col {
    margin-bottom: 30px;
}
  
}


@media (max-width: 768px) {
  
  .course_desc .desc_row .content {
    padding: 0;
    margin: 20px 0 0;
}
  
  .contact_section {
    [class*='col-'] {
      margin-bottom: 30px;
    }
  }
  
  
.side-entry {
    position: relative !important;
    top: 0 !important;
    margin: 0;
    margin-top: 30px;
  
  .menu-title {
    display: none;
   
}
  
 .menu-holder {
    max-width: inherit;
    background: #fff;
    padding: 20px;
     width: 100%;
    box-shadow: 0 0 2px 0 rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.24);
}
  
   
}  
  
  .intro_courses {
    .col {
      margin-bottom: 30px;
    }
  }
  

  
}



@media (max-width: 668px) {

.page-header.short-header {
    height: 150px;
    min-height: inherit;
    max-height: inherit;
    position: relative;
}
  
  .page-header .entry-content h1 {
   
    font-size: 28px;
    line-height: 1;
}
  
}
