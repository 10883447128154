.top-header {
  
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0px;
    z-index: 99;
    position: relative;
    top:0;
    width: 100%;
    //background: rgba(0, 0, 0, 0.7);
    .brand { 
      width: 180px;
      height: 45.25px;
      position: relative;
      display: block;
      /* text-indent: -9999em; */
      float: left;
      top: 10px;
      
      svg#logo {
        width: 100%;
        display: block;
      }
      
    }
}
.main-nav {
    float: right;
}
.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: transparent;
    border: none;
}

.navbar-nav > li {
    float: none;
    display: inline-block;
    a {
        color: $drk;
        margin: 0 10px;
        padding: 23px 10px 24px;
        position: relative;
        display: inline-block;
       @include easing;
        &:hover {
            color: $red;
            text-decoration: none;
            &:after {
                opacity: 1;
            }
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 7px;
            right: 7px;
            background: $red;
            height: 3px;
            opacity: 0;
            @include easing;
        }
    }
    &.menu-item-has-children {
        a {
            &:after {
                display: none;
            }
        }
    }
    &.active {
        a {
            &:after {
                opacity: 1;
            }
        }
    }
}
@media (max-width: 1200px) {
    .sign-nav {
        display: none;
    }
    .mobilemenu .sign-nav {
        display: block;
    }
    .sign-nav {
        float: none;
        margin-top: 0px;
    }
    .navbar-nav > li {
        margin: 0 5px;
    }
}
.navbar-nav li a {
    font-family: "ToyotaType-Semibold";
}
.navbar-nav > li.active a {
    color: $red;
}
.nav > li > a:focus,
.nav > li > a:hover {
    color: $red;
    text-decoration: none;
    background-color: transparent;
    @include easing;
}
.navbar-nav > li,
.navbar-nav > li .sub-menu li {
    position: relative;
    display: inline-block;
}
/*
.navbar-nav > li:after {
    content: "";
    position: absolute;
    bottom: 9px;
    left: 9px;
    right: 9px;
    display: block;
    height: 2px;
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background-color: #ddd;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
*/

.navbar-nav > li.active:after {
    background: $red;
}
.navbar-nav > li.active a {
    color: $red;
}
/*
.navbar-nav > li .sub-menu li:after {
    content: "";
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    display: block;
    height: 2px;
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background-color: #ddd;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
*/

.navbar-nav > li .sub-menu li:hover:after,
.navbar-nav > li:hover:after {
    @include easing;
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}
.navbar-nav > li .sub-menu li.active:after,
.navbar-nav > li.active:after {
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}
.navbar-nav>li>.sub-menu li.active a {
    color: $red;
   
}
.navbar-nav > li .sub-menu {
    opacity: 0;
    z-index: -10;
    visibility: hidden;
}
.navbar-nav > li .sub-menu {
    margin-top: 0;
    -webkit-transition: top 0.3s ease, opacity 0.3s ease;
    -moz-transition: top 0.3s ease, opacity 0.3s ease;
    -o-transition: top 0.3s ease, opacity 0.3s ease;
    transition: top 0.3s ease, opacity 0.3s ease;
    float: left;
    width: 235px;
    position: absolute;
    top: 70px;
    padding-top: 5px;
    background: url('../images/drop_down_arrow.png') center top no-repeat;
}
.navbar-nav > li:hover .sub-menu {
    z-index: 10;
    margin-top: 0;
    top: 55px;
    opacity: 1.0;
    visibility: visible;
}
.navbar-nav > li > .sub-menu {
    border: 1px solid #dcdcdc;
    list-style-type: none;
    background: #ffffff;
    text-align: left;
    padding: 10px 0;
    left: 0;
    margin-left: 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .06), 0 4px 5px 0 rgba(0, 0, 0, .06), 0 1px 10px 0 rgba(0, 0, 0, .08);
    -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .06), 0 4px 5px 0 rgba(0, 0, 0, .06), 0 1px 10px 0 rgba(0, 0, 0, .08);
    li {
        list-style: none;
        display: block;
        a {
            color: $drk;
            padding: 10px 10px;
            display: block;
            line-height: 1;
            @include easing;
            &:hover {
                text-decoration: none;
                color: $red;
            }
        }
    }
}
.sub-menu:after {
    top: 0;
    left: 60px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 0px;
    margin-left: 0;
    border: 8px solid black;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -1px 1px 0px 0 rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: -1px 1px 0px 0 rgba(0, 0, 0, 0.17);
    z-index: 0;
}
.hamburger-menu {
    display: none;
}
.mobilemenu {
    display: none;
}
@media (max-width: 992px) {
    .main-nav {
        display: none;
    }
   .top-header {
    
    padding: 10px 0;
     
   .brand {
    width: 140px;
   
    top: 0;
} 
     
     
}
    .hamburger-menu {
        display: block;
    }
  
  
  .inner-nav {
    padding-top: 30px;
  }
  .accordion-menu {
    text-align: left;
    
    .menu-item-has-children>a {
    display: none;
      
    }
    
    .menu-item-has-children {
      margin: 0;
      .active {
        font-weight: bold;
      }
    }
    
    
    li {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      ul {
        display: block;
      }
      .sub-menu {
        margin: 0;
        padding: 0;
        
      }
    }
    
    .menu-microcat-login {
      margin-top: 10px;
      a {
        background: $red;
        padding: 8px 20px;
        color: #fff;
        text-align: center;
      }
    }
    
    
  }
  
  

  ._open {
    overflow: hidden;
    padding-top: 65px;
    
    .top-header {
    background: #fff;
    padding: 10px 0;
    position: fixed;
    z-index: 99999;
      width: 100%;
      }
  }

  
  
  
    .menu-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        background: hsla(0, 0%, 0%, 0.26);
        opacity: 0;
        z-index: 1;
        visibility: hidden;
        -webkit-transition: all 1s cubic-bezier(.23, 1, .32, 1);
        -moz-transition: all 1s cubic-bezier(.23, 1, .32, 1);
        -o-transition: all 1s cubic-bezier(.23, 1, .32, 1);
        transition: all 1s cubic-bezier(.23, 1, .32, 1);
    }
    .menu-overlay.darken {
        opacity: 1;
        visibility: visible;
    }
    .mobilemenu {
        .sign-nav {
            .navbar-nav {
                margin: 0;
            }
        }
    }
    // Mobile Menu
    .mobilemenu {
        display: none;
    }
    $bar-width: 26px;
    $bar-height: 2px;
    $bar-spacing: 8px;
    .hamburger-menu {
        position: absolute;
        top: 20px;
        right: 30px;
        margin: auto;
        z-index: 99999;
        width: $bar-width;
        height: $bar-height + $bar-spacing*2;
        cursor: pointer;
    }
    .bar,
    .bar:after,
    .bar:before {
        width: $bar-width;
        height: $bar-height;
    }
    .bar {
        position: relative;
        transform: translateY($bar-spacing);
        background: #000;
        transition: all 0ms 300ms;
        &.animate {
            background: rgba(255, 255, 255, 0);
        }
    }
    .bar:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: $bar-spacing;
        background: #000;
        transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .bar:after {
        content: "";
        position: absolute;
        left: 0;
        top: $bar-spacing;
        background: #000;
        transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .bar.animate:after {
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .bar.animate:before {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
        ;
    }
    .mobilemenu.active {
        right: 0;
        box-shadow: -8px 0 25px -4px rgba(10, 18, 26, .36);
    }
    .mobilemenu {
        display: block;
        top: 0;
        position: fixed;
        right: -50%;
        width: 50%;
        height: 100vh;
        background: white;
        z-index: 9999;
        padding: 60px 15px;
        text-align: center;
        @include cubic();
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto;
        /* position: absolute; */
        overflow-y: auto;
     -webkit-overflow-scrolling: touch;

        height: 100%;
        
       
        .brand {
            display: block;
            float: none;
            width: 100px;
            height: 100px;
            background-size: contain;
            margin: 0 auto 30px;
        }
        #closeMenu {
            position: absolute;
            background: #002857;
            color: white;
            width: 50px;
            height: 50px;
            line-height: 50px;
            left: -50px;
            top: 0;
            font-size: 36px;
            opacity: 0;
            transition: opacity .3s ease-in-out;
            -moz-transition: opacity .3s ease-in-out;
            -webkit-transition: opacity .3s ease-in-out;
            transition-delay: .2s;
        }
        &.active {
            right: 0;
            -webkit-box-shadow: -8px 0px 25px -4px rgba(10, 18, 26, 0.36);
            -moz-box-shadow: -8px 0px 25px -4px rgba(10, 18, 26, 0.36);
            box-shadow: -8px 0px 25px -4px rgba(10, 18, 26, 0.36);
            #closeMenu {
                opacity: 1;
            }
        }
    }
}
@media (max-width: 668px) {
  .top-header .brand {
   
    top: 5px;
}
    .hamburger-menu {
        top: 24px;
        right: 20px;
    }
    .mobilemenu {
        right: -100%;
        width: 100%;
    }
	
.hero-header svg {
   display: none;
   
}	
	
}