@media (min-width: 990px) {
     .side-entry.affix {
         position: fixed;
         top: 20px;
         margin-top: 0;
         left: inherit;
         right: inherit;
         width: 335px;
         margin: 0;
         .menu-title h2 a {
             color: $drk;
        }
         .w-circle {
             background:$red;
             i {
                 color: #fff;
            }
        }
    }
  
  .side-entry.affix-bottom {
   .menu-title h2 a {
             color: $drk;
        }
         .w-circle {
             background:$red;
             i {
                 color: #fff;
            }
        }
}
  
}




 .side-entry {
     position: absolute;
     top: -143px;
     margin: 0 15px;
     z-index: 5;
     left: 0;
     right: 0;
     .menu-title {
         display: block;
         margin: 0 0 20px;
         h2 a {
             color: #fff;
             font-size: 26px;
             line-height: 1;
             margin: 0;
             text-transform: uppercase;
             display: inline-block;
        }
       
       
       
    }
     .w-circle {
         width: 60px;
         height: 60px;
         border-radius: 50%;
         background: #fff;
         display: inline-block;
         z-index: 5;
         margin-right: 15px;
         text-align: center;
         top: 3px;
         position: relative;
         i {
             line-height: 58px;
             font-size: 36px;
             font-weight: bold;
             color:$drk;
        }
    }
     .menu-holder {
         max-width: 300px;
         background: #fff;
       padding: 20px;
       box-shadow: 0 0 2px 0 rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.24);
       h4 {
         font-size: 16px;
         line-height: 1;
         font-family: "ToyotaType-Semibold";
        margin: 0 0 10px;
       }
       
         ul {
             margin: 0;
             padding: 0;
             li {
                 list-style: none;
            }
        }
    }
}
 .event-sidebar {
     li:last-child {
         .entry {
             border: none;
        }
    }
     .entry {
         padding: 20px;
         color: $drk;
         font-weight: 600;
         text-transform: uppercase;
         border-bottom: 1px solid #7c2879;
         display: block;
         background: #fff;
         i {
             display: inline-block;
             font-size: 25px;
             line-height: 1;
             position: relative;
             vertical-align: top;
             margin: 0 10px 0 0;
        }
         span {
             display: inline-block;
        }
    }
     .readmore {
         padding: 0;
         a {
             padding: 15px 15px;
             display: inline-block;
             width: 100%;
        }
         .circle-arrow {
             background: $red;
             width: 45px;
             height: 45px;
             line-height: 45px;
             i {
                 color: #fff;
                 font-size: 32px;
                 line-height: 45px;
                 margin: 0;
            }
        }
         .link-txt {
             line-height: 45px;
        }
    }
}
