.circle-arrow {
    background: #fff;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    display: block;
    border-radius: 50%;
    float: right;
}


.btn.btn-primary,  .wpcf7-submit {
  font-family: "ToyotaType-Semibold";
    color: #fff;
    background: none;
    border: 1px solid $red;
    color: $red;
    border-radius: 0;
    padding: 12px 40px;
   @include ease_out();
  &:hover {
     background: $red;
      color: #fff;
  }
}


.btn-primary.btn-fill {
  background: $red;
  color: #fff;
  &:hover {
    background: rgba(215, 25, 33, 0);
    color: $red;
  }
}

.ticket-selector-submit-btn-wrap {
  width: 100%;
}

.ticket-selector-submit-btn, .spco-next-step-btn {
    font-family: "ToyotaType-Semibold";
    color: #fff;
    background: none;
    border: 1px solid $red;
    color: $red !important;
    padding: 12px 40px;
    display: block;
    width: 100%;
   cursor: pointer;
  @include ease_out();
  &:hover {
      background: $red;
      color: #fff !important;
  }
}

.spco-next-step-btn {
    font-family: "ToyotaType-Semibold";
    color: #fff;
    background: none;
    border: 1px solid $red;
    color: $red !important;
    padding: 12px 40px;
  @include ease_out();
  &:hover {
      background: $red;
      color: #fff !important;
  }
}