

@mixin v-align_outer() {
    display: table;
    table-layout: fixed;
    width: 100%;
  
}


@mixin v-align_inner() {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}


@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin drop_shadow() {
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12), 0px 2px 2px 0px rgba(0,0,0,0.24);
   -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12), 0px 2px 2px 0px rgba(0,0,0,0.24);
   box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12), 0px 2px 2px 0px rgba(0,0,0,0.24);
}

@mixin lg_drop_shadow() {
   -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12), 0px 6px 6px 0px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12), 0px 6px 6px 0px rgba(0, 0, 0, 0.24);
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12), 0px 6px 6px 0px rgba(0, 0, 0, 0.24);
}


@mixin no_drop_shadow() {
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.24);
   -moz-box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.24);
   box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.24);
}






@mixin ease_out() {
        -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
}

@mixin cubic() { 
    -webkit-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
    transition:  all 1s cubic-bezier(0.23, 1, 0.32, 1);
}




      // Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$red: #D71921;
$drk: #252525;
$lt_blue: #edf0f5;


@mixin easing {
     -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@mixin quick_easing {
     -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

@mixin cubic() { 
    -webkit-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
    transition:  all 1s cubic-bezier(0.23, 1, 0.32, 1);
}


@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


@mixin dropshadow() { 
 box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .06), 0 4px 5px 0 rgba(0, 0, 0, .06), 0 1px 10px 0 rgba(0, 0, 0, .08);
 -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .06), 0 4px 5px 0 rgba(0, 0, 0, .06), 0 1px 10px 0 rgba(0, 0, 0, .08);
}


@mixin rest_position {
              opacity: 0;
              -webkit-transform: translateY(20px);
              transform: translateY(20px);
}


@mixin hover_position {
              opacity: 1;
              -webkit-transform: translateY(0);
              transform: translateY(0);
              transition: opacity 1s cubic-bezier(.19,1,.22,1),-webkit-transform 1s cubic-bezier(.19,1,.22,1);
              transition: opacity 1s cubic-bezier(.19,1,.22,1),transform 1s cubic-bezier(.19,1,.22,1);
              transition: opacity 1s cubic-bezier(.19,1,.22,1),transform 1s cubic-bezier(.19,1,.22,1),-webkit-transform 1s cubic-bezier(.19,1,.22,1);
              transition-delay: .1s;
}





/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    margin-top: -30px;
    padding: 0;

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
	z-index:5;
	
    display: block;
    width: 60px;
    height: 60px;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
  
	//background:url(../images/arrow_right_white_24px.svg) no-repeat 0 0;
	background-size: 60px;
	display:block;

    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: 25px;
}

.slick-prev:before
{
  content: '';
	//background:url(../images/arrow_left_white_24px.svg) no-repeat 0 0;
	background-size: 60px;
  display: block;
}

.slick-next
{
    right: 25px;
}

.slick-next:before
{
    content: '';
}
