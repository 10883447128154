.espresso-event-wrapper-dv {
  .event-content {
    h4 {
      margin: 0 0 20px;
    }
    
    ul {
      padding-left: 20px;
      li {
        color: #5a5a5a;
      }
    }
    
    strong {
       font-family: ToyotaType-Semibold;
       font-weight: normal;
      em {
        font-style: normal;
      }
    }
    
    
    }
  
  [itemprop="address"] {
       color: #5a5a5a;
    }
  
}


#map_canvas {
  width: 100%;
  height: 500px;
}