
@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=181132ce-e6ee-4864-9d93-64ce614b4f53");
    @font-face{
        font-family: "ToyotaType-Regular";
        src:url("../fonts/ToyotaType-Regular.eot?#iefix");
        src:url("../fonts/ToyotaType-Regular.eot?#iefix") format("eot"),url("../fonts/ToyotaType-Regular.woff2") format("woff2"),url("../fonts/ToyotaType-Regular.woff") format("woff"),url("../fonts/ToyotaType-Regular.ttf") format("truetype"),url("../fonts/ToyotaType-Regular.svg") format("svg");
    }
    @font-face{
        font-family: "ToyotaType-Book";
        src:url("../fonts/ToyotaType-Book.eot?#iefix");
        src:url("../fonts/ToyotaType-Book.eot?#iefix") format("eot"),url("../fonts/ToyotaType-Book.woff2") format("woff2"),url("../fonts/ToyotaType-Book.woff") format("woff"),url("../fonts/ToyotaType-Book.ttf") format("truetype"),url("../fonts/ToyotaType-Book.svg") format("svg");
    }
    @font-face{
         font-family: "ToyotaType-Light";
        src:url("../fonts/ToyotaType-Light.eot?#iefix");
        src:url("../fonts/ToyotaType-Light.eot?#iefix") format("eot"),url("../fonts/ToyotaType-Light.woff2") format("woff2"),url("../fonts/ToyotaType-Light.woff") format("woff"),url("../fonts/ToyotaType-Light.ttf") format("truetype"),url("../fonts/ToyotaType-Light.svg") format("svg");
    }
    @font-face{
         font-family: "ToyotaType-Semibold";
        src:url("../fonts/ToyotaType-Semibold.eot?#iefix");
        src:url("../fonts/ToyotaType-Semibold.eot?#iefix") format("eot"),url("../fonts/ToyotaType-Semibold.woff2") format("woff2"),url("../fonts/ToyotaType-Semibold.woff") format("woff"),url("../fonts/ToyotaType-Semibold.ttf") format("truetype"),url("../fonts/ToyotaType-Semibold.svg") format("svg");
    }
    @font-face{
        font-family: "ToyotaType-Bold";
        src:url("../fonts/ToyotaType-Bold.eot?#iefix");
        src:url("../fonts/ToyotaType-Bold.eot?#iefix") format("eot"),url("../fonts/ToyotaType-Bold.woff2") format("woff2"),url("../fonts/ToyotaType-Bold.woff") format("woff"),url("../fonts/ToyotaType-Bold.ttf") format("truetype"),url("../fonts/ToyotaType-Bold.svg") format("svg");
    }
    @font-face{
        font-family: "ToyotaType-Black";
        src:url("../fonts/ToyotaType-Black.eot?#iefix");
        src:url("../fonts/ToyotaType-Black.eot?#iefix") format("eot"),url("../fonts/ToyotaType-Black.woff2") format("woff2"),url("../fonts/ToyotaType-Black.woff") format("woff"),url("../fonts/ToyotaType-Black.ttf") format("truetype"),url("../fonts/ToyotaType-Black.svg") format("svg");
    }


body {
    font-family: "ToyotaType-Book";
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1.4;
    //padding-top: 70px;
}
body,
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

a {
    color: $red;
    &:hover {
        color: $red;
        text-decoration: none;
    }
}
:focus {
    outline: none;
}

.btn-primary.focus, .btn-primary:focus {
    box-shadow:none;
}

.btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    background-color:transparent;
    background-image: none;
    border-color: transparent;
}

p {
    margin: 0 0 30px;
    color: #5a5a5a;
}
figure {
    margin: 0;
}
h1,
h2,
h3 {
    font-weight: 200;
    font-family: "ToyotaType-Semibold";
}

strong, b {
   font-family: "ToyotaType-Semibold";
  font-weight: normal;
}

:focus {
    outline: none !important;
}

.m-top {
  margin-top: 65px;
}


.modal {
    transition: .3s ease-in-out !important;
    -webkit-transition: .3s ease-in-out !important;
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
  button.close {
    position: absolute;
    top: 10px;
    right: 15px;
  }
  
  .header {
    h5 {
      margin: 0 0 10px;
      
    }
    p {
      font-size: 14px;
      
    }
  }
  
   .modal-body {
     padding: 0;
}
}

.modal.fade .modal-dialog {

    -webkit-transform: translate(0, 0) !important;
     transform: translate(0, 0) !important;
  
 
}


.modal.show {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.modal{
    transition: .5s ease-in-out;
    opacity: 0;
    -webkit-transform: scale(.5);
    transform: scale(.5);
}

.modal-content {
    border-radius: 0;
    border: none;
    padding: 30px;

.Modal-closeBtnIcon {
  fill: $drk;
  height: 14px;
  width: 14px;
}

.modal-header {
  border: none;
}


}




.section_title {
  margin-bottom: 45px;
  h3 {
    font-size: 48px;
    line-height: 1;
    margin: 0 0 25px;
  }
  p {
    max-width: 650px;
    font-size: 18px;
    line-height: 1.3;
    margin: auto;
    color: #565656;
  }
}


.red-title {
  color: $red;
  font-size: 42px;
}

//project grid
.project_grid {
    .thumbnail {
        margin-bottom: 30px;
       @include ease_out();
       @include drop_shadow();
        .img-holder {
            margin: 0;
          img {
            width: 100%;
          }
        }
        .desc {
            padding: 15px;
            h4 {
                margin: 0 0 10px;
				    font-size: 22px;
            }
            p {
                margin: 0;
               font-weight: 300;
            }
        }
        .readmore {
            padding: 10px;
            overflow: hidden;
            border-top: 1px solid $red;
            .link-txt {
                float: left;
                position: relative;
                line-height: 45px;
                font-weight: 700;
                color: $red;
                text-transform: uppercase;
            }
            .circle-arrow {
               
                width: 45px;
                height: 45px;
                line-height: 45px;
                i {
                    color: #fff;
                    font-size: 32px;
                }
            }
            a {
                display: block;
            }
        }
    }
    .overlay_view {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 11;
        padding: 10px;
        width: 100%;
        height: 100%;
        text-align: center;
        @include ease_out();
        opacity: 0;
        background: rgba(0, 0, 0, 0.4);
        a {
            display: block;
            width: 100%;
            height: 100%;
            color: #fff;
        }
        .more {
               position: absolute;
              padding: 9px 10px;
              border: none;
              /* font-weight: 700; */
              color: #fff;
              /* text-transform: uppercase; */
              font-size: 24px;
              line-height: 1;
              top: 50%;
              left: 50%;
           // opacity: 0;
            -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
        }
    }
    figure {
        position: relative;
        overflow: hidden;
    }
    figure:hover .overlay_view {
        opacity: 1;
        background: rgba(214, 24, 32, 0.7);
        .more {
           // opacity: 1;
         //   -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
           // transform: translateY(-50%) translateX(-50%) scale(1);
        }
    }
  .thumbnail:hover {
       @include lg_drop_shadow();
    }
 
}
.project_grid  {
.article.active figure .overlay_view {
   
  
}

  .article.active .thumbnail {
       @include lg_drop_shadow();
    }
  
  .article.active figure .overlay_view .more {
    opacity: 1;
    -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
    transform: translateY(-50%) translateX(-50%) scale(1);
}
}
@media (max-width: 668px) {
.section_title h3 {
    font-size: 32px;
    
}
  
  h1 {
   font-size: 28px;
}
  
  h2 {
    font-size: 26px !important;
  }
  
  h3 {
    font-size: 22px !important;
  }
  
.red-title {
   
    font-size: 26px;
}  
  
  
  .project_grid .overlay_view {
   
    opacity: 1;
  
}
  
  
}

